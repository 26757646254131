import React, { useState, useEffect, useRef } from "react";
import { useLuckMeter } from "./useLuckMeter";
import { titleize } from "app/utils/strings";
import { horoscopeSignDictionary } from "app/dictionaries/horoscopeSignDictionary";
import ReactPlayer from "react-player";
import { get } from "lodash";
import { COLORS } from "app/dictionaries/colors";
import classNames from "classnames";
import MoonEllipseIcon from "app/assets/icons/transparentMoonEllipse.png";
import { Tooltip } from "@nextui-org/react";
import EcruToolTipSvg from "app/assets/icons/ecruTooltip.svg";
import CountUp from "react-countup";

const cardDiamondsPositions = [
  {
    top: 6,
    left: 6,
  },
  {
    top: 6,
    right: 6,
  },
  {
    bottom: 6,
    left: 6,
  },
  {
    bottom: 6,
    right: 6,
  },
];

export interface LuckMeterProps {
  helpText: string;
  stats: any;
  animationDelayCount: number;
  horoscopeSign: string;
}

export const LuckMeter: React.FC<LuckMeterProps> = ({
  helpText,
  stats,
  animationDelayCount,
  horoscopeSign,
}) => {
  const {
    meterStats,
    averageScore,
    setIsTooltipVisible,
    isTooltipVisible,
    onLuckAveragePress,
  } = useLuckMeter({ stats, animationDelayCount });

  const horoscope = get(horoscopeSignDictionary, horoscopeSign.toLowerCase());

  return (
    <div
      className={`relative p-5 pt-8 pb-2 flex justify-between items-center bg-[#0B0B0B] border border-[#1A1B1E] rounded`}
    >
      {horoscopeSign && (
        <div className="absolute -top-14 left-5 p-2 w-20 h-20 rounded-full bg-black border border-[#DBD2BE] overflow-hidden flex items-center justify-center">
          <div className="w-12 h-12 absolute top-4 left-1/2 transform -translate-x-1/2 scale-150">
            <img
              src={MoonEllipseIcon}
              alt="moon"
              className="transform scale-110 absolute top-0 left-0"
            />
            <ReactPlayer
              url={horoscope.video}
              playing
              muted
              loop
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    playsInline: true,
                  },
                },
              }}
            />
          </div>
        </div>
      )}
      {cardDiamondsPositions.map((position, index) => (
        <div
          key={index}
          className="absolute w-1 h-1 bg-[#2C2E33] transform rotate-45"
          style={position}
        />
      ))}
      <div className="flex w-full">
        <div className="flex flex-col items-start mb-3 gap-3 w-full mr-5">
          <div className="flex gap-2">
            <h2 className={`text-${COLORS.PE} text-2xl font-aquino`}>
              Vibe Meter
            </h2>
            <Tooltip className="!bg-black rounded-sm" content={helpText}>
              <img src={EcruToolTipSvg} alt="tooltip" />
            </Tooltip>
          </div>
          {/* <div className="relative">
            {isTooltipVisible && (
              <div className="absolute top-[-6.5rem] max-w-sm p-2 bg-black border border-gray-700 text-yellow-500">
                <p>{helpText}</p>
              </div>
            )}
            <button onClick={() => setIsTooltipVisible(!isTooltipVisible)}>
              <EcruTooptipSvg />
            </button>
          </div> */}

          <div className="flex flex-col gap-[10px] w-full">
            {Object.entries(meterStats).map(([key, value], index) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between h-5"
                >
                  <span
                    className={`text-${COLORS.PE} text-sm w-12 mr-1 font-aquino`}
                  >
                    {titleize(key)}
                  </span>
                  <div className="flex-grow bg-[#5C533E] h-1 rounded-[1px] mr-2">
                    <div
                      className={classNames(
                        `bg-gradient-to-r from-[#5C533E] to-[#DBD2BE] h-full rounded]`
                      )}
                      style={{ width: `${value}%` }}
                    />
                  </div>

                  <CountUp
                    end={value}
                    duration={3}
                    delay={1}
                    className="font-aquino text-sm w-4"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="bg-black">
        <div
          className={`p-2 max-h-[132px] w-28 flex flex-col items-center gap-2 rounded border border-[#DBD2BE]`}
          style={{
            boxShadow:
              "0px 0px 12px 0px #1B1914 , 0px 0px 6px 0px #5E5747 inset",
          }}
        >
          <p className="text-[#DBD2BE] text-sm">Average</p>

          <CountUp
            end={averageScore}
            duration={3}
            delay={1}
            className="font-aquino text-[70px]"
          />
        </div>
      </div>
    </div>
  );
};

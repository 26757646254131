import type { ComponentType } from "react";
import { motion, useSpring } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import styles from "./withClickHoc.module.css";
import CardTexturePng from "app/assets/images/cards/texture.png";
import classnames from "classnames";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { delay } from "app/utils/delay";

//Spring animation parameters
const spring = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

interface WithClickProps {
  width: string;
  height: string;
  children: React.ReactNode;
  card: any;
  sizeOfRadialGradient?: sizeOfRadiantGradientProps;
  isFlippable: boolean;
  shouldDoInitialFlip?: boolean;
  spotLightable?: boolean;
  onClick?: () => void;
}

export enum sizeOfRadiantGradientProps {
  SMALL = "25rem",
  LARGE = "25rem",
}

export function withClick(Component: any): ComponentType<WithClickProps> {
  return (props: any) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const { isBelowLg, isAboveLg } = useBreakpoint("lg");
    const { isBelowMd, isAboveMd } = useBreakpoint("md");
    const [rotateXaxis, setRotateXaxis] = useState(0);
    const [rotateYaxis, setRotateYaxis] = useState(0);
    const [overlayStyle, setOverlayStyle] = useState({});
    const ref = useRef(null);

    const handleMouseMove = (event: any) => {
      const element = ref.current as any;
      const elementRect = element.getBoundingClientRect();
      const elementWidth = elementRect.width;
      const elementHeight = elementRect.height;
      const elementCenterX = elementWidth / 2;
      const elementCenterY = elementHeight / 2;
      const mouseX = event.clientY - elementRect.y - elementCenterY;
      const mouseY = event.clientX - elementRect.x - elementCenterX;
      const degreeX = (mouseX / elementWidth) * 20; //The number is the rotation factor
      const degreeY = (mouseY / elementHeight) * 20; //The number is the rotation factor
      setRotateYaxis(degreeY);
      setRotateXaxis(degreeX);
      const x = event.clientX - elementRect.left;
      const y = event.clientY - elementRect.top;
      const overlayRadialGradientSize = props.sizeOfRadialGradient;
      setOverlayStyle({
        "--opacity": 1,
        "--x": `${x}px`,
        "--y": `${y}px`,
        background: `radial-gradient(${overlayRadialGradientSize} ${overlayRadialGradientSize} at var(--x) var(--y), #523C3C 0%, rgba(110, 96, 82, 0.51) 13.34%, rgba(92, 96, 112, 0.36) 26.56%, rgba(0, 0, 0, 0.00) 57.73%)`,
      });
    };

    const handleClick = () => {
      if (props.isFlippable) {
        setIsFlipped((prevState) => !prevState);
        props.onClick?.();
      }
    };

    const handleMouseEnd = () => {
      setRotateXaxis(0);
      setRotateYaxis(0);
      setOverlayStyle({});
    };

    const dx = useSpring(0, spring);
    const dy = useSpring(0, spring);

    useEffect(() => {
      dx.set(-rotateXaxis);
      dy.set(rotateYaxis);
    }, [rotateXaxis, rotateYaxis]);

    useEffect(() => {
      const tarotCardNumber = null;

      setTimeout(() => {
        if (props.isFlippable && tarotCardNumber) {
          setIsFlipped(tarotCardNumber === props.card.number);
        }
      }, 1000);
    }, []);

    useEffect(() => {
      (async () => {
        if (props.shouldDoInitialFlip) {
          await delay(1000);
          setIsFlipped(true);
        }
      })();
    }, []);

    return (
      <motion.div
        onClick={handleClick}
        transition={spring}
        style={{
          perspective: "1200px",
          transformStyle: "preserve-3d",
          width: `${props.width}`,
          height: `${props.height}`,
          cursor: "pointer",
        }}
      >
        <motion.div
          ref={ref}
          whileHover={{ scale: 1.03 }} //Change the scale of zooming in when hovering
          onMouseMove={isAboveMd ? handleMouseMove : undefined}
          onMouseLeave={isAboveMd ? handleMouseEnd : undefined}
          onPan={isBelowMd ? handleMouseMove : undefined} // Pan for small screens
          onPanEnd={isBelowMd ? handleMouseEnd : undefined} //
          // onPan={handleMouseMove}
          // onPanEnd={handleMouseEnd}
          // onMouseMove={isAboveLg ? handleMouseMove : () => {}}
          // onMouseLeave={isAboveLg ? handleMouseEnd : () => {}}
          // onPan={isBelowLg ? handleMouseMove : () => {}}
          // onPanEnd={isBelowLg ? handleMouseEnd : () => {}}
          transition={spring}
          style={{
            width: "100%",
            height: "100%",
            rotateX: dx,
            rotateY: dy,
            overflow: "hidden",
          }}
        >
          {/* back */}
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
            className={classnames(styles.card, styles.cardBack)}
          >
            <img
              src={CardTexturePng}
              alt="card texture"
              className={classnames(styles.texture_bg, {
                "opacity-60": props.spotLightable,
              })}
            />
            <Component
              {...props}
              variant="Back"
              style={{
                width: "100%",
                height: "100%",
              }}
              className={styles.cta}
            />
          </motion.div>
          <div className={classnames(styles.overlay)} style={overlayStyle}>
            <motion.div
              animate={{ rotateY: isFlipped ? -180 : 0 }}
              transition={spring}
              style={{
                width: "100%",
                height: "100%",
                zIndex: isFlipped ? 0 : 1,
                backfaceVisibility: "hidden",
                position: "absolute",
              }}
              className={classnames(styles.card)}
            >
              <img
                src={CardTexturePng}
                alt="card texture"
                className={classnames(styles.texture_bg, {
                  "opacity-60": props.spotLightable,
                })}
              />
              <Component
                {...props}
                variant="Back"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                className={styles.cta}
              />
            </motion.div>
          </div>
          {/* front */}
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
            className={classnames(styles.card, styles.cardFront)}
          >
            <img
              src={CardTexturePng}
              alt="card texture"
              className={classnames(styles.texture_bg, {
                "opacity-60": props.spotLightable,
              })}
            />
            <Component
              {...props}
              variant="Front"
              style={{
                width: "100%",
                height: "100%",
              }}
              className={styles.cta}
            />
          </motion.div>
          <div className={classnames(styles.overlay)} style={overlayStyle}>
            <motion.div
              initial={{ rotateY: 180 }}
              animate={{ rotateY: isFlipped ? 0 : 180 }}
              transition={spring}
              style={{
                width: "100%",
                height: "100%",
                zIndex: isFlipped ? 1 : 0,
                backfaceVisibility: "hidden",
                position: "absolute",
              }}
              className={classnames(styles.card)}
            >
              <img
                src={CardTexturePng}
                alt="card texture"
                className={classnames(styles.texture_bg, {
                  "opacity-60": props.spotLightable,
                })}
              />
              <Component
                {...props}
                variant="Front"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                className={classnames(styles.cta)}
              />
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    );
  };
}

import * as React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { sentenceize, titleize } from "app/utils/strings";

export interface NativeSelectProps {
  options: { key: string; title: string }[];
  backgroundColor?: string;
  selectedOption?: any;
  onSelectedOptionChange: (selectedOption: string) => void;
}

export const NativeSelect: React.FC<NativeSelectProps> = ({
  options,
  backgroundColor = "bg-[#1A1814]",
  selectedOption,
  onSelectedOptionChange,
}) => {
  return (
    <Select
      placeholder={selectedOption && `their ${sentenceize(selectedOption)}`}
      value={selectedOption}
      onChange={(value) => onSelectedOptionChange(value.target.value)}
      style={{
        background: "#1A1814",
        borderRadius: "4px",
      }}
      scrollShadowProps={{
        isEnabled: false,
      }}
      classNames={{
        base: "border-0",
        innerWrapper: "!p-0 h-[64px]",
        popoverContent: `!p-0 rounded-md !${backgroundColor}`,
        listboxWrapper: `border-0 rounded-md`,
        label: `!text-[#DBD2BE] text-2xl font-aquino`,
        value: `!text-[#DBD2BE] text-2xl font-aquino`,
      }}
    >
      {options.map((option) => (
        <SelectItem
          key={option.key}
          classNames={{
            title: `text-lg font-aquino p-2 ${
              selectedOption === option.key
                ? ""
                : "hover:!text-[#1A1814] focus:!text-[#1A1814] pressed:!text-[#1A1814] selected:!text-[#1A1814]"
            } `,
            base: ` p-0 w-[210px] ${
              selectedOption === option.key
                ? "!bg-transparent"
                : "data-[hover=true]:!bg-[#DBD2BE] data-[focus=true]:!bg-[#DBD2BE]"
            }`,
          }}
          value={option.key}
        >
          {option.title}
        </SelectItem>
      ))}
    </Select>
  );
};

import * as React from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import grained from "grained";

enum QuizTypes {
  QUIZLET_1 = "quizlet-1",
}

export const useQuiz = () => {
  const { type = QuizTypes.QUIZLET_1 }: { type?: QuizTypes } = useParams();

  // useEffect(() => {
  //   const options = {
  //     animate: true,
  //     patternWidth: 100,
  //     patternHeight: 100,
  //     grainOpacity: 0.07,
  //     grainDensity: 1,
  //     grainWidth: 1,
  //     grainHeight: 1,
  //   };
  //   window.grained("#grained", options);
  // }, [grained]);

  return {
    type,
  };
};

import http from "app/axios/axiosInstance";
import { useQuery } from "app/utils/useQuery";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export const useProfileCreationInvite = () => {
  const query = useQuery();
  const token = query.get("token");
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [inviterName, setInviterName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [screenIndex, setScreenIndex] = useState(0);
  const [relationshipProfile, setRelationshipProfile] = useState({
    name: "",
    relationship: "",
    gender: "",
    birthDate: null,
    birthplaceName: null,
    birthplaceLatitude: null,
    birthplaceLongitude: null,
  } as any);
  const [horoscopeReport, setHoroscopeReport] = useState(null);

  const checkIfTokenIsValid = async () => {
    if (!token) {
      // show conditional screen
      return console.log("No token found");
    }
    try {
      const { data } = await http.post(
        `/v1/relationship_profile_invites/validate_token`,
        {
          token,
        }
      );
      setInviterName(data.inviter);
      toast(
        `${data.inviter} wants to make a fun horoscope profile for you! Can you help? 2 min max🌟 Your data is safe and secure. This link expires in 24 hours.`
      );
      setIsTokenValid(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleProfileCreation = async () => {
    try {
      setIsLoading(true);
      const { data } = await http.post(
        `/v1/relationship_profile_invites/create_relationship_profile`,
        {
          relationshipProfile,
          token,
        }
      );
      setHoroscopeReport(data.horoscopeReport);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsTokenValid(false);
      // Redirect to the invalid token page
    }
  };

  useEffect(() => {
    checkIfTokenIsValid();
  }, []);

  return {
    isTokenValid,
    handleProfileCreation,
    inviterName,
    screenIndex,
    setScreenIndex,
    relationshipProfile,
    setRelationshipProfile,
    isLoading,
    horoscopeReport,
  };
};

import * as React from "react";
import { useBirthPlaceScreen } from "./useBirthPlaceScreen";
import { Input } from "@nextui-org/react";
import { COLORS } from "app/dictionaries/colors";

export interface BirthPlaceScreenProps {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}

export const BirthPlaceScreen: React.FC<BirthPlaceScreenProps> = ({
  relationshipProfile,
  setRelationshipProfile,
}) => {
  const {
    birthPlaceInputText,
    onLocationInputChange,
    locationSuggestions,
    onLocationSelect,
  } = useBirthPlaceScreen({
    relationshipProfile,
    setRelationshipProfile,
  });
  return (
    <div className="w-full py-4 flex flex-col items-center">
      <div className="w-full mb-2.5">
        <h1 className="font-aquino text-2xl text-[COLORS.PE] max-w-[300px]">
          Birth place
        </h1>
        <p className="text-[COLORS.HMD2]">
          Use city if possible, if not use country.
        </p>
      </div>
      <Input
        style={{
          color: COLORS.PE,
          fontFamily: "Public Sans",
          fontSize: "24",
          marginTop: "10px",
          borderColor: COLORS.PE,
        }}
        placeholder="Search here.."
        classNames={{
          inputWrapper: `border-[#DBD2BE]`,
          input: `w-full !w-max-[414px] !text-[#DBD2BE]`,
        }}
        value={birthPlaceInputText}
        size="lg"
        isClearable
        type="text"
        variant="underlined"
        onChange={(e) => onLocationInputChange(e.target.value)}
      />
      <ul className="w-full">
        {locationSuggestions.map(({ id, name, longitude, latitude }, index) => (
          <li
            key={index}
            className="w-full p-2.5 pl-0 cursor-pointer"
            onClick={() => onLocationSelect({ name, longitude, latitude })}
          >
            <p className="text-lg text-[COLORS.PE]">{name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

import { NextUIProvider } from "@nextui-org/react";
import "./App.css";
import { Router } from "./routes/router";
import { useApp } from "./useApp";
import { ToastContainer, Zoom } from "react-toastify";

function App() {
  const { navigate } = useApp();

  return (
    <NextUIProvider navigate={navigate}>
      <Router />
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        transition={Zoom}
        className={"!w-full !max-w-[414px] !p-2"}
        rtl={false}
        hideProgressBar={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </NextUIProvider>
  );
}

export default App;

import { useBreakpoint } from "app/utils/useBreakpoint";
import classNames from "classnames";
import * as React from "react";

export interface TitleDescriptionProps {
  title: string;
  description: string;
}

export const TitleDescription: React.FC<TitleDescriptionProps> = ({
  title,
  description,
}) => {
  const { isAboveMd, isBelowMd } = useBreakpoint("md");

  return (
    <div className="flex flex-col gap-1 justify-center items-center mb-[10%]">
      <p
        className={classNames("text-[#8f8671]", {
          "text-sm": isBelowMd,
        })}
      >
        {title}
      </p>
      <p
        className={classNames("font-semibold", {
          "text-2xl": isAboveMd,
          "text-lg": isBelowMd,
        })}
      >
        {description}
      </p>
    </div>
  );
};

import * as React from "react";
import { sizeOfRadiantGradientProps, withClick } from "./withClickHoc";
import CardBackPng from "app/assets/images/cards/cardBack.png";
import classnames from "classnames";
import { find } from "lodash";
import { tarotCardDictionary } from "app/dictionaries/tarotCardDictionary";

// You might have additional props for front and back content
interface CardProps {
  width: string;
  height: string;
  className?: string;
  card: any;
  onClick?: () => void;
  isFlippable?: boolean;
  sizeOfRadialGradient?: sizeOfRadiantGradientProps;
  shouldDoInitialFlip?: boolean;
  spotLightable?: boolean;
  styles?: any;
}

export const Card: React.FC<CardProps> = ({
  width,
  height,
  className,
  card,
  onClick,
  isFlippable = true,
  sizeOfRadialGradient = sizeOfRadiantGradientProps.LARGE,
  shouldDoInitialFlip = false,
  spotLightable = true,
  styles,
}) => {
  // Define the card content directly within this component
  const cardContent = find(tarotCardDictionary, { name: card.name });

  const frontContent = (
    <img className="h-full w-full" src={cardContent?.image} />
  );

  const backContent = (
    <img className="h-full w-full opacity-70" src={CardBackPng} />
  );

  const CardContent = ({ variant, ...props }: any) => {
    return (
      <div
        id="tarotCard"
        className={classnames(className)}
        styles={styles}
        {...props}
      >
        {variant === "Front" ? frontContent : backContent}
      </div>
    );
  };

  // Enhance the CardContent with the withClick HOC
  const EnhancedComponent = withClick(CardContent);

  return (
    <EnhancedComponent
      width={width}
      height={height}
      card={card}
      sizeOfRadialGradient={sizeOfRadialGradient}
      isFlippable={isFlippable}
      shouldDoInitialFlip={shouldDoInitialFlip}
      spotLightable={spotLightable}
      onClick={onClick}
    >
      {frontContent} {/* Your front content */}
      {backContent} {/* Your back content */}
    </EnhancedComponent>
  );
};

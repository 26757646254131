import AriesPng from "app/assets/horoscopeSign/lunarAries.png";
import AriesMp4 from "app/assets/horoscopeSign/lunarAries.mp4";
import TaurusPng from "app/assets/horoscopeSign/lunarTaurus.png";
import TaurusMp4 from "app/assets/horoscopeSign/lunarTaurus.mp4";
import GeminiPng from "app/assets/horoscopeSign/lunarGemini.png";
import GeminiMp4 from "app/assets/horoscopeSign/lunarGemini.mp4";
import CancerPng from "app/assets/horoscopeSign/lunarCancer.png";
import CancerMp4 from "app/assets/horoscopeSign/lunarCancer.mp4";
import LeoPng from "app/assets/horoscopeSign/lunarLeo.png";
import LeoMp4 from "app/assets/horoscopeSign/lunarLeo.mp4";
import VirgoPng from "app/assets/horoscopeSign/lunarVirgo.png";
import VirgoMp4 from "app/assets/horoscopeSign/lunarVirgo.mp4";
import LibraPng from "app/assets/horoscopeSign/lunarLibra.png";
import LibraMp4 from "app/assets/horoscopeSign/lunarLibra.mp4";
import ScorpioPng from "app/assets/horoscopeSign/lunarScorpio.png";
import ScorpioMp4 from "app/assets/horoscopeSign/lunarScorpio.mp4";
import SagittariusPng from "app/assets/horoscopeSign/lunarSagittarius.png";
import SagittariusMp4 from "app/assets/horoscopeSign/lunarSagittarius.mp4";
import CapricornPng from "app/assets/horoscopeSign/lunarCapricorn.png";
import CapricornMp4 from "app/assets/horoscopeSign/lunarCapricorn.mp4";
import AquariusPng from "app/assets/horoscopeSign/lunarAquarius.png";
import AquariusMp4 from "app/assets/horoscopeSign/lunarAquarius.mp4";
import PiscesPng from "app/assets/horoscopeSign/lunarPisces.png";
import PiscesMp4 from "app/assets/horoscopeSign/lunarPisces.mp4";

export const enum HoroscopeSignDictionaryEnum {
  ARIES = "aries",
  TAURUS = "taurus",
  GEMINI = "gemini",
  CANCER = "cancer",
  LEO = "leo",
  VIRGO = "virgo",
  LIBRA = "libra",
  SCORPIO = "scorpio",
  SAGITTARIUS = "sagittarius",
  CAPRICORN = "capricorn",
  AQUARIUS = "aquarius",
  PISCES = "pisces",
}

export const horoscopeSignDictionary = {
  [HoroscopeSignDictionaryEnum.ARIES]: {
    name: HoroscopeSignDictionaryEnum.ARIES,
    image: AriesPng,
    video: AriesMp4,
  },
  [HoroscopeSignDictionaryEnum.TAURUS]: {
    name: HoroscopeSignDictionaryEnum.TAURUS,
    image: TaurusPng,
    video: TaurusMp4,
  },
  [HoroscopeSignDictionaryEnum.GEMINI]: {
    name: HoroscopeSignDictionaryEnum.GEMINI,
    image: GeminiPng,
    video: GeminiMp4,
  },
  [HoroscopeSignDictionaryEnum.CANCER]: {
    name: HoroscopeSignDictionaryEnum.CANCER,
    image: CancerPng,
    video: CancerMp4,
  },
  [HoroscopeSignDictionaryEnum.LEO]: {
    name: HoroscopeSignDictionaryEnum.LEO,
    image: LeoPng,
    video: LeoMp4,
  },
  [HoroscopeSignDictionaryEnum.VIRGO]: {
    name: HoroscopeSignDictionaryEnum.VIRGO,
    image: VirgoPng,
    video: VirgoMp4,
  },
  [HoroscopeSignDictionaryEnum.LIBRA]: {
    name: HoroscopeSignDictionaryEnum.LIBRA,
    image: LibraPng,
    video: LibraMp4,
  },
  [HoroscopeSignDictionaryEnum.SCORPIO]: {
    name: HoroscopeSignDictionaryEnum.SCORPIO,
    image: ScorpioPng,
    video: ScorpioMp4,
  },
  [HoroscopeSignDictionaryEnum.SAGITTARIUS]: {
    name: HoroscopeSignDictionaryEnum.SAGITTARIUS,
    image: SagittariusPng,
    video: SagittariusMp4,
  },
  [HoroscopeSignDictionaryEnum.CAPRICORN]: {
    name: HoroscopeSignDictionaryEnum.CAPRICORN,
    image: CapricornPng,
    video: CapricornMp4,
  },
  [HoroscopeSignDictionaryEnum.AQUARIUS]: {
    name: HoroscopeSignDictionaryEnum.AQUARIUS,
    image: AquariusPng,
    video: AquariusMp4,
  },
  [HoroscopeSignDictionaryEnum.PISCES]: {
    name: HoroscopeSignDictionaryEnum.PISCES,
    image: PiscesPng,
    video: PiscesMp4,
  },
};

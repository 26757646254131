import * as React from "react";
import { useQuiz } from "./useQuiz";
import { Quizlet1 } from "./types/quizlet1/quizlet1";
import { Fireflies } from "app/components/fireflies/fireflies";
import classNames from "classnames";
import styles from "./quiz.module.css";
import { MoonSteps } from "app/components/moonsteps/moonSteps";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface QuizProps {}

export const Quiz: React.FC<QuizProps> = (props) => {
  const { type } = useQuiz();

  const getType = () => {
    switch (type) {
      case "quizlet-1":
        return <Quizlet1 />;
      default:
        return <Quizlet1 />;
    }
  };

  const quizType = getType();

  const { isAboveMd } = useBreakpoint("md");

  return (
    <>
      <div className="relative w-full h-[100dvh] overflow-hidden">
        {/* <Fireflies
          color="#5C4E2F"
          shadowColor="#423925"
          negativeZIndex={false}
        /> */}
        {isAboveMd && (
          <>
            <div className="border-1 border-[#423925] rounded-full bg-black w-[700px] h-[700px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-[1240px] md:h-[1240px]" />
            <div className="border-1 border-[#423925] rounded-full bg-black w-[678px] h-[678px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-[1200px] md:h-[1200px]" />
          </>
        )}
        {/* <div className={classNames(styles.animatedFrontalGlow, {})} />
        <div className={classNames(styles.animatedBgGlow)} />
        <div className={styles.movingBg} />
        <div id="grained" className={classNames(styles.grained, {})} /> */}
        <div className={styles.hmcTitleBlock}>{/* <HmcBlockTitle /> */}</div>
      </div>
      <div className="flex top-0 absolute left-1/2 -translate-x-1/2 p-1 pt-10 h-[100dvh] w-full justify-center overflow-hidden">
        {quizType}
      </div>
    </>
  );
};

import * as React from "react";
import { RelationshipGoalTypes } from "app/quiz/types/quizlet1/useQuizlet1";
import { useEffect, useState } from "react";
import { random } from "lodash";
import { tarotCardDictionary } from "app/dictionaries/tarotCardDictionary";
import { useQuery } from "app/utils/useQuery";
import { useNavigate } from "react-router";

export const useScreen3 = ({
  setRelationshipGoal,
}: {
  setRelationshipGoal: (relationshipGoal: RelationshipGoalTypes) => void;
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState<any>(
    tarotCardDictionary[random(0, tarotCardDictionary.length - 1)]
  );
  const [hasCardBeenFlipped, setHasCardBeenFlipped] = useState(false);

  const onOptionClick = (value: RelationshipGoalTypes) => {
    setRelationshipGoal(value);
  };

  useEffect(() => {
    if (hasCardBeenFlipped) {
      query.set("complete", "true");
      navigate(`/quiz/quizlet1?${query.toString()}`);
      setTimeout(() => {
        window.location.href =
          "https://apps.apple.com/app/id6479682201?platform=iphone";
      }, 5000);
    }
  }, [hasCardBeenFlipped]);

  return {
    onOptionClick,
    selectedCard,
    setSelectedCard,
    hasCardBeenFlipped,
    setHasCardBeenFlipped,
  };
};

import Agate from "app/assets/luckyStones/agate.png";
import Amethyst from "app/assets/luckyStones/amethyst.png";
import Aquamarine from "app/assets/luckyStones/aquamarine.png";
import Aventurine from "app/assets/luckyStones/aventurine.png";
import BlackTourmaline from "app/assets/luckyStones/blackTourmaline.png";
import Bloodstone from "app/assets/luckyStones/bloodstone.png";
import Carnelian from "app/assets/luckyStones/carnelian.png";
import Chrysocolla from "app/assets/luckyStones/chrysocolla.png";
import Citrine from "app/assets/luckyStones/citrine.png";
import Diamond from "app/assets/luckyStones/diamond.png";
import Emerald from "app/assets/luckyStones/emerald.png";
import Fluorite from "app/assets/luckyStones/fluorite.png";
import Garnet from "app/assets/luckyStones/garnet.png";
import GoldenTopaz from "app/assets/luckyStones/goldenTopaz.png";
import Hematite from "app/assets/luckyStones/hematite.png";
import Jade from "app/assets/luckyStones/jade.png";
import LapisLazuli from "app/assets/luckyStones/lapisLazuli.png";
import LibyanDesertGlass from "app/assets/luckyStones/libyanDesertGlass.png";
import Malachite from "app/assets/luckyStones/malachite.png";
import Moonstone from "app/assets/luckyStones/moonstone.png";
import MossAgate from "app/assets/luckyStones/mossAgate.png";
import Obsidian from "app/assets/luckyStones/obsidian.png";
import Onyx from "app/assets/luckyStones/onyx.png";
import Opal from "app/assets/luckyStones/opal.png";
import Pearl from "app/assets/luckyStones/pearl.png";
import Rhodochrosite from "app/assets/luckyStones/rhodochrosite.png";
import RoseQuartz from "app/assets/luckyStones/roseQuartz.png";
import Ruby from "app/assets/luckyStones/ruby.png";
import Sapphire from "app/assets/luckyStones/sapphire.png";
import Sardonyx from "app/assets/luckyStones/sardonyx.png";
import Sodalite from "app/assets/luckyStones/sodalite.png";
import Sunstone from "app/assets/luckyStones/sunstone.png";
import TigersEye from "app/assets/luckyStones/tigersEye.png";
import Topaz from "app/assets/luckyStones/topaz.png";
import Turquoise from "app/assets/luckyStones/turquoise.png";
import { camelCase } from "lodash";

export const getLuckyStone = (name: string) => {
  switch (camelCase(name)) {
    case "agate":
      return Agate;
    case "amethyst":
      return Amethyst;
    case "aquamarine":
      return Aquamarine;
    case "aventurine":
      return Aventurine;
    case "blackTourmaline":
      return BlackTourmaline;
    case "bloodstone":
      return Bloodstone;
    case "carnelian":
      return Carnelian;
    case "chrysocolla":
      return Chrysocolla;
    case "citrine":
      return Citrine;
    case "diamond":
      return Diamond;
    case "emerald":
      return Emerald;
    case "fluorite":
      return Fluorite;
    case "garnet":
      return Garnet;
    case "goldenTopaz":
      return GoldenTopaz;
    case "hematite":
      return Hematite;
    case "jade":
      return Jade;
    case "lapisLazuli":
      return LapisLazuli;
    case "libyanDesertGlass":
      return LibyanDesertGlass;
    case "malachite":
      return Malachite;
    case "moonstone":
      return Moonstone;
    case "mossAgate":
      return MossAgate;
    case "obsidian":
      return Obsidian;
    case "onyx":
      return Onyx;
    case "opal":
      return Opal;
    case "pearl":
      return Pearl;
    case "rhodochrosite":
      return Rhodochrosite;
    case "roseQuartz":
      return RoseQuartz;
    case "ruby":
      return Ruby;
    case "sapphire":
      return Sapphire;
    case "sardonyx":
      return Sardonyx;
    case "sodalite":
      return Sodalite;
    case "sunstone":
      return Sunstone;
    case "tigersEye":
      return TigersEye;
    case "topaz":
      return Topaz;
    case "turquoise":
      return Turquoise;
    default:
      return Agate;
  }
};

import classNames from "classnames";
import * as React from "react";
import { TitleDescription } from "app/quiz/types/quizlet1/components/titleDescription";
import { RelationshipGoalTypes } from "app/quiz/types/quizlet1/useQuizlet1";
import { SecondaryButton3 } from "app/components/buttons/button";
import { Card as TarotCard } from "app/components/tarotCards/component/card";
import { useScreen3 } from "./useScreen3";
import { AppleBtn } from "app/components/buttons/apple/appleBtn";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface Screen3Props {
  relationshipGoal: RelationshipGoalTypes | null;
  setRelationshipGoal: (relationshipGoalType: RelationshipGoalTypes) => void;
  setCurrentScreen: (screen: number) => void;
}

const goalOptions = [
  {
    title: (
      <p>
        <span role="img" aria-label="hearts" className="mr-2">
          💕
        </span>{" "}
        Improve relationship
      </p>
    ),
    value: RelationshipGoalTypes.IMPROVE_RELATIONSHIP,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="broken heart" className="mr-2">
          💔
        </span>{" "}
        Fix relationship
      </p>
    ),
    value: RelationshipGoalTypes.FIX_RELATIONSHIP,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="ring" className="mr-2">
          🔗
        </span>{" "}
        Build a strong marriage
      </p>
    ),
    value: RelationshipGoalTypes.BUILD_A_STRONG_MARRIAGE,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="xlovey" className="mr-2">
          👩‍❤️‍👨
        </span>{" "}
        Check compatibility
      </p>
    ),
    value: RelationshipGoalTypes.CHECK_COMPATIBILITY,
  },
  {
    title: (
      <p>
        <span role="img" aria-label="thinking face" className="mr-2">
          🫶
        </span>{" "}
        All of the above
      </p>
    ),
    value: RelationshipGoalTypes.ALL_OF_THE_ABOVE,
  },
];

export const Screen3: React.FC<Screen3Props> = ({
  relationshipGoal,
  setRelationshipGoal,
}) => {
  const {
    onOptionClick,
    selectedCard,
    hasCardBeenFlipped,
    setHasCardBeenFlipped,
  } = useScreen3({ setRelationshipGoal });

  const { isBelowSm, isAboveSm } = useBreakpoint("sm");
  const { isBelowMd, isAboveMd } = useBreakpoint("md");

  const memoizeTarotCard = React.useMemo(() => {
    return (
      <TarotCard
        card={selectedCard}
        width={isAboveMd ? "175px" : "125px"}
        height={isAboveMd ? "382px" : "270px"}
        shouldDoInitialFlip={false}
        onClick={() => {
          setHasCardBeenFlipped(true);
        }}
      />
    );
  }, []);

  return (
    <div
      className={classNames("flex flex-col items-center duration-300", {
        "mt-14": !hasCardBeenFlipped,
        "mt-0": hasCardBeenFlipped,
      })}
    >
      {!relationshipGoal && (
        <>
          <div
            className={classNames("", {
              "mb-[34%]": isAboveMd,
              "mb-[5%]": isBelowMd,
            })}
          >
            <TitleDescription
              title="Personal assessment quizlet"
              description="What is your goal?"
            />
          </div>
          <div className="flex flex-col gap-10 items-center w-[300px]">
            {goalOptions.map(({ title, value }) => (
              <SecondaryButton3
                key={value}
                className={classNames(
                  "w-full !px-10 !py-[13px] flex justify-start"
                )}
                onClick={() => onOptionClick(value)}
              >
                {title}
              </SecondaryButton3>
            ))}
          </div>
        </>
      )}
      {relationshipGoal && (
        <>
          <TitleDescription
            title="Personal assessment quizlet"
            description="Lastly, flip a tarot for your relationship."
          />
          {memoizeTarotCard}
          <div
            className={classNames("flex flex-col items-center gap-4", {
              "opacity-0": !hasCardBeenFlipped,
              "opacity-100": hasCardBeenFlipped,
            })}
          >
            <p
              className={classNames("font-aquino mt-4 mb-2", {
                "text-[40px]": isAboveMd,
                "text-[28px]": isBelowMd,
              })}
            >
              {selectedCard.name}
            </p>
            <p
              className={classNames("max-w-[497px] text-center", {
                "text-2xl mb-2": isAboveMd,
                "text-md max-w-[85vw]": isBelowMd,
              })}
            >
              Get your detailed personalized psychic reading and discover what
              this Tarot card means for your Zodiac sign with our app.
            </p>
            <AppleBtn />
          </div>
        </>
      )}
    </div>
  );
};

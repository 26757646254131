export enum RelationshipTypes {
  ROMANTIC_PARTNER = "romantic_partner",
  FRIEND = "friend",
  FAMILY = "family",
  ENEMY = "enemy",
  BOSS = "boss",
  COLLEAGUE = "colleague",
  RATHER_NOT_SAY = "rather_not_say",
  MOM = "mom",
  DAD = "dad",
  GRANDMA = "grandma",
  GRANDPA = "grandpa",
  PET = "pet",
  CRUSH = "crush",
}

export const relationshipProfileOrder = [
  RelationshipTypes.ROMANTIC_PARTNER,
  RelationshipTypes.CRUSH,
  RelationshipTypes.PET,
  RelationshipTypes.MOM,
  RelationshipTypes.DAD,
  RelationshipTypes.GRANDMA,
  RelationshipTypes.GRANDPA,
  RelationshipTypes.FAMILY,
  RelationshipTypes.FRIEND,
  RelationshipTypes.ENEMY,
  RelationshipTypes.BOSS,
  RelationshipTypes.COLLEAGUE,
  RelationshipTypes.RATHER_NOT_SAY,
];

export const sortedRelationshipProfiles = (relationshipProfiles: any) =>
  [...relationshipProfiles].sort(
    (a, b) =>
      relationshipProfileOrder.indexOf(a.relationship) -
      relationshipProfileOrder.indexOf(b.relationship)
  );

export const relationshipTypesList = [
  { key: RelationshipTypes.RATHER_NOT_SAY, title: "I'd rather not say" },
  { key: RelationshipTypes.ROMANTIC_PARTNER, title: "their romantic partner" },
  { key: RelationshipTypes.MOM, title: "their mom" },
  { key: RelationshipTypes.DAD, title: "their dad" },
  { key: RelationshipTypes.GRANDMA, title: "their grandma" },
  { key: RelationshipTypes.GRANDPA, title: "their grandpa" },
  { key: RelationshipTypes.FAMILY, title: "their family member" },
  { key: RelationshipTypes.FRIEND, title: "their friend" },
  { key: RelationshipTypes.ENEMY, title: "their enemy" },
  { key: RelationshipTypes.BOSS, title: "their boss" },
  { key: RelationshipTypes.COLLEAGUE, title: "their colleague" },
  { key: RelationshipTypes.PET, title: "their pet" },
  { key: RelationshipTypes.CRUSH, title: "their crush" },
];

import * as React from "react";
import { TimeInput } from "@nextui-org/react";
import { Time } from "@internationalized/date";
import { useBirthTimeScreen } from "./useBirthTimeScreen";

export interface BirthTimeScreenProps {
  relationshipProfile: any;
  setRelationshipProfile: (profile: any) => void;
}

export const BirthTimeScreen: React.FC<BirthTimeScreenProps> = ({
  relationshipProfile,
  setRelationshipProfile,
}) => {
  const { handleTimeChange } = useBirthTimeScreen({
    relationshipProfile,
    setRelationshipProfile,
  });

  return (
    <div className="flex flex-col items-center h-full w-full ">
      <div className="flex flex-col mt-5 gap-1">
        <h1 className="font-aquino text-2xl text-[COLORS.PE]">Birth time</h1>
        <p className="text-[COLORS.HMD2]  leading-6">
          As accurate as possible. If not sure, as close as possible or use
          12:00 pm.
        </p>
      </div>

      <div className="flex items-center bg-black rounded-lg mt-5 w-full">
        {/* <DatePicker
          onChange={(date) =>
            setRelationshipProfile({ ...relationshipProfile, birthDate: date })
          }
          className="w-64"
        /> */}
        <TimeInput
          onChange={handleTimeChange}
          fullWidth
          classNames={{
            base: " max-w-[414px]",
            inputWrapper: `!bg-[#1A1814] rounded-sm h-16 hover:!bg-[#1A1814] focus:!bg-[#1A1814] selected:!bg-[#1A1814]`,
            input: `!text-[#DBD2BE]`,
            segment: `!text-[#DBD2BE]`,
            label: `!text-[#DBD2BE]`,
          }}
          color="default"
          defaultValue={new Time(12, 0, 0, 0)}
          label="Birth time"
        />
      </div>
    </div>
  );
};

import FoolPng from "app/assets/images/cards/tarotV1/fool.png";
import MagicianPng from "app/assets/images/cards/tarotV1/magician.png";
import HighPriestessPng from "app/assets/images/cards/tarotV1/highPriestess.png";
import EmpressPng from "app/assets/images/cards/tarotV1/empress.png";
import EmperorPng from "app/assets/images/cards/tarotV1/emperor.png";
import HierophantPng from "app/assets/images/cards/tarotV1/hierophant.png";
import LoversPng from "app/assets/images/cards/tarotV1/lovers.png";
import ChariotPng from "app/assets/images/cards/tarotV1/chariot.png";
import StrengthPng from "app/assets/images/cards/tarotV1/strength.png";
import HermitPng from "app/assets/images/cards/tarotV1/hermit.png";
import WheelOfFortunePng from "app/assets/images/cards/tarotV1/wheelOfFortune.png";
import JusticePng from "app/assets/images/cards/tarotV1/justice.png";
import HangedManPng from "app/assets/images/cards/tarotV1/hangedMan.png";
import DeathPng from "app/assets/images/cards/tarotV1/death.png";
import TemperancePng from "app/assets/images/cards/tarotV1/temperance.png";
import DevilPng from "app/assets/images/cards/tarotV1/devil.png";
import TowerPng from "app/assets/images/cards/tarotV1/tower.png";
import StarPng from "app/assets/images/cards/tarotV1/star.png";
import MoonPng from "app/assets/images/cards/tarotV1/moon.png";
import SunPng from "app/assets/images/cards/tarotV1/sun.png";
import JudgementPng from "app/assets/images/cards/tarotV1/judgement.png";
import WorldPng from "app/assets/images/cards/tarotV1/world.png";

export const tarotCardDictionary = [
  {
    name: "The Fool",
    number: 0,
    image: FoolPng,
  },
  {
    name: "The Magician",
    number: 1,
    image: MagicianPng,
  },
  {
    name: "The High Priestess",
    number: 2,
    image: HighPriestessPng,
  },
  {
    name: "The Empress",
    number: 3,
    image: EmpressPng,
  },
  {
    name: "The Emperor",
    number: 4,
    image: EmperorPng,
  },
  {
    name: "The Hierophant",
    number: 5,
    image: HierophantPng,
  },
  {
    name: "The Lovers",
    number: 6,
    image: LoversPng,
  },
  {
    name: "The Chariot",
    number: 7,
    image: ChariotPng,
  },
  {
    name: "Strength",
    number: 8,
    image: StrengthPng,
  },
  {
    name: "The Hermit",
    number: 9,
    image: HermitPng,
  },
  {
    name: "Wheel of Fortune",
    number: 10,
    image: WheelOfFortunePng,
  },
  {
    name: "Justice",
    number: 11,
    image: JusticePng,
  },
  { name: "The Hanged Man", number: 12, image: HangedManPng },
  { name: "Death", number: 13, image: DeathPng },
  { name: "Temperance", number: 14, image: TemperancePng },
  { name: "The Devil", number: 15, image: DevilPng },
  { name: "The Tower", number: 16, image: TowerPng },
  { name: "The Star", number: 17, image: StarPng },
  { name: "The Moon", number: 18, image: MoonPng },
  { name: "The Sun", number: 19, image: SunPng },
  { name: "Judgement", number: 20, image: JudgementPng },
  { name: "The World", number: 21, image: WorldPng },
];
